import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Layout from './components/Layout';
import Login from './components/Login';
import SurveyReports from './components/SurveyReports';
import BasicSurvey from './components/SurveyForms/BasicSurvey/BasicSurvey';
import DetailedSurvey from './components/SurveyForms/DetailedSurvey/detailedSurvey';
import FormB from './components/SurveyForms/FormB/formB';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }else{
      setIsLoggedIn(false);
    }
  }, []);

  return (
    <Router>
      {isLoggedIn ? (
        <Layout setIsLoggedIn={setIsLoggedIn}>
          <Routes>
            <Route path="/surveyOptions" element={<SurveyReports />} />
            <Route path="/basicSurvey" element={<BasicSurvey />} />
            <Route path="/detailedSurvey" element={<DetailedSurvey />} />
            <Route path="/formB" element={<FormB />} />
            {/* <Route path="/amc" element={<AMC />} /> AMC Route */}
          </Routes>
        </Layout>
      ) : (
        <Routes>
          <Route path="/" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;