import React from 'react';

export default function Navbar() {
  return (
    <nav className="w-full bg-white text-black fixed top-0 left-0 z-10 h-16 flex items-center px-4 shadow-md">
      <div className="flex items-center space-x-8">
        <img src="/logo.jpg" alt="Company Logo" width={50} height={50} />
        <h1 className="text-xl font-bold">NFPA India Pvt. Ltd.</h1>
      </div>
    </nav>
  )
}