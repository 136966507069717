import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

export default function Layout({ children, setIsLoggedIn }) {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex flex-1 pt-16">
        <Sidebar setIsLoggedIn = {setIsLoggedIn}/>
        {/* The ml-64 shows the margin left of the layout of the children.. If in sidebar we change the w-64 then we need to change the same */}
        <main className="flex-1 p-4 ml-64">
          {children}
        </main>
      </div>
    </div>
  )
};