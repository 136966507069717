import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LogOut } from 'lucide-react';

function Logout({setIsLoggedIn}) {
  const navigate = useNavigate();

  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to log out?');

    if (confirmLogout) {
      localStorage.removeItem('token');
      setIsLoggedIn(false);
      navigate('/');
    }
  };

  return (
    <button 
      onClick={handleLogout} 
      className="flex items-center px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition-colors duration-300"
    >
      <LogOut size={18} className="mr-2" />
      Logout
    </button>
  );
}

export default Logout;