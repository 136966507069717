import React from "react";
import styles from "./BasicSurvey.module.css";
import { useLocation } from 'react-router-dom';
import Letterhead from "../letterHead/letterHead";
// import DownloadButton from "../DownloadButton/downloadButton";

const BasicSurvey = () => {

  const location = useLocation();
  const { survey } = location.state || {};

  if (!survey) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Letterhead />

      <div className={styles.typeOfProd}>
        <h3 className={styles.typeOfProdH3}>Survey Report</h3>
      </div>

        <div className={styles.basicInfoTable}>
          <table>
                <tr>
                    <td className= {`${styles.small} ${styles.width50} ${styles.basicInfoTd}`}>Name of Client: {survey.customerDetails.client_name ?? 'NA'}</td>
                    <td>Contact Person 1: {survey.customerDetails.contact_person_1 ?? 'NA'}</td>
                    <td>Date: {survey.date}</td>
                </tr>
                <tr>
                    <td className= {`${styles.small} ${styles.width50}`}>Address: {survey.customerDetails.address ?? 'NA'}</td>
                    <td>Contact Person 2: {survey.customerDetails.contact_person_2  ?? 'NA'}</td>
                    <td>Report No: {survey.report_id}</td>
                </tr>
            </table>
        </div>

        {survey.fireExtinguishers && survey.fireExtinguishers.length > 0 ? (
            <>
                <div className={styles.typeOfProd}>
                    <h3 className={styles.typeOfProdH3}>Fire Extinguishers</h3>
                </div>

                <div className={styles.basicInfoTable}>
                    <table>
                        <thead>
                            <tr>
                                <th>SrNo</th>
                                <th>Type</th>
                                <th>Capacity</th>
                                <th>Qty</th>
                                <th>Make</th>
                                <th>Last Refilled Date</th>
                                <th>Next Refilling Date</th>
                                <th>Last HPT Date</th>
                                <th>Next HPT Date</th>
                                <th>Manufacturing Date</th>
                                <th>Last Maintained By</th>
                                <th>Remarks</th>
                            </tr>
                        </thead>
                        <tbody>
                            {survey.fireExtinguishers.map((extinguisher, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{extinguisher.Type ?? 'NA'}</td>
                                    <td>{extinguisher.Capacity ?? 'NA'}</td>
                                    <td>{extinguisher.Quantity ?? 'NA'}</td>
                                    <td>{extinguisher.Make ?? 'NA'}</td>
                                    <td>{extinguisher.Last_Refilled_Date ?? 'NA'}</td>
                                    <td>{extinguisher.Next_Refilling_Date ?? 'NA'}</td>
                                    <td>{extinguisher.Last_Hpt_Date ?? 'NA'}</td>
                                    <td>{extinguisher.Next_Hpt_Date ?? 'NA'}</td>
                                    <td>{extinguisher.Manufacturing_Date ?? 'NA'}</td>
                                    <td>{extinguisher.Last_Maintained_By ?? 'NA'}</td>
                                    <td>{extinguisher.Remarks ?? 'NA'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        ) : null}

        {survey.fireHydrant ? (
            <>
                <div className={styles.typeOfProd}>
                    <h3 className={styles.typeOfProdH3}>Fire Hydrant System</h3>
                </div>

                <div className={styles.hydrant}>
                    <table className={styles.basicInfoTable}>
                        <tbody>
                            <tr>
                                <td className={styles.width40} rowSpan="2">Single Headed</td>
                                <td className={styles.width30}>Internal</td>
                                <td>{survey.fireHydrant.Single_Headed?.Internal ?? 'NA'}</td>
                            </tr>
                            <tr>
                                <td className={styles.width30}>External</td>
                                <td>{survey.fireHydrant.Single_Headed?.External ?? 'NA'}</td>
                            </tr>
                            <tr>
                                <td className={styles.width40} rowSpan="2">Double Headed</td>
                                <td className={styles.width30}>Internal</td>
                                <td>{survey.fireHydrant.Double_Headed?.Internal ?? 'NA'}</td>
                            </tr>
                            <tr>
                                <td className={styles.width30}>External</td>
                                <td>{survey.fireHydrant.Double_Headed?.External ?? 'NA'}</td>
                            </tr>
                            <tr>
                                <td className={styles.width40} rowSpan="2">Single Door Hose Box</td>
                                <td className={styles.width30}>Internal</td>
                                <td>{survey.fireHydrant.Single_Door_Hose_Box?.Internal ?? 'NA'}</td>
                            </tr>
                            <tr>
                                <td className={styles.width30}>External</td>
                                <td>{survey.fireHydrant.Single_Door_Hose_Box?.External ?? 'NA'}</td>
                            </tr>
                            <tr>
                                <td className={styles.width40} rowSpan="2">Double Door Hose Box</td>
                                <td className={styles.width30}>Internal</td>
                                <td>{survey.fireHydrant.Double_Door_Hose_Box?.Internal ?? 'NA'}</td>
                            </tr>
                            <tr>
                                <td className={styles.width30}>External</td>
                                <td>{survey.fireHydrant.Double_Door_Hose_Box?.External ?? 'NA'}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table className={styles.basicInfoTable}>
                        <tbody>
                            <tr>
                                <td className={styles.width40} rowSpan="2">2 - Way Inlet</td>
                                <td>{JSON.stringify(survey.fireHydrant?.Two_Way_Inlet ?? 'NA')}</td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td className={styles.width40} rowSpan="2">3 - Way Inlet</td>
                                <td>{JSON.stringify(survey.fireHydrant?.Three_Way_Inlet ?? 'NA')}</td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td className={styles.width40} rowSpan="2">4 - Way Inlet</td>
                                <td>{JSON.stringify(survey.fireHydrant?.Four_Way_Inlet ?? 'NA')}</td>
                            </tr>
                            <tr></tr>
                            <tr>
                                <td className={styles.width40} rowSpan="2">Hose Reel Drum</td>
                                <td>{survey.fireHydrant?.Hose_Reel_Drum ?? 'NA'}</td>
                            </tr>
                            <tr></tr>
                        </tbody>
                    </table>

                </div>
                <div className={styles.remarks}>Remarks: { survey.fireHydrant?.Remarks ?? 'NA' }</div>
            </>
        ) : null}


        {survey.fireAlarm ? (
            <>
                <div className={styles.typeOfProd}>
                    <h3 className={styles.typeOfProdH3}>FIRE ALARM & DETECTION SYSTEM</h3>
                </div>

                <div className={styles.alarmDetection}>
                    <div className={styles.span2}>
                        <table>
                                <tr>
                                    <td className={styles.width20} rowspan="2">Fire Alarm Panel</td>
                                    <td className={styles.width20}>Addressable</td>
                                    <td>{JSON.stringify(survey.fireAlarm.Fire_Alarm_Panel?.Addressable  ?? 'NA')}</td>
                                    <td className={styles.width20}>No. of Loops</td>
                                    <td>{survey.fireAlarm.Fire_Alarm_Panel?.No_Of_Loops ?? 'NA'}</td>
                                </tr>
                                <tr>
                                    <td className={styles.width20}>Conventional</td>
                                    <td>{JSON.stringify(survey.fireAlarm.Fire_Alarm_Panel?.Conventional ?? 'NA')}</td>
                                    <td className={styles.width20}>No. of Zones</td>
                                    <td>{survey.fireAlarm.Fire_Alarm_Panel?.No_Of_Zones ?? 'NA'}</td>
                                </tr>
                        </table>
                    </div>
                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width40} rowspan="2">Make</td>
                                    <td>{survey.fireAlarm.Fire_Alarm_Panel?.Make ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>
                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width40} rowspan="2">Model No.</td>
                                    <td>{survey.fireAlarm.Fire_Alarm_Panel?.Model_No ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>

                    <div className={styles.span2}>
                        <table>
                                <tr>
                                    <td className={styles.width20} rowspan="2">Repeater Panel</td>
                                    <td className={styles.width20}>Addressable</td>
                                    <td>{JSON.stringify(survey.fireAlarm.Repeater_Panel?.Addressable ?? 'NA')}</td>
                                    <td className={styles.width20}>No. of Loops</td>
                                    <td>{survey.fireAlarm.Repeater_Panel?.No_Of_Loops ?? 'NA'}</td>
                                </tr>
                                <tr>
                                    <td className={styles.width20}>Conventional</td>
                                    <td>{JSON.stringify(survey.fireAlarm.Repeater_Panel?.Conventional ?? 'NA')}</td>
                                    <td className={styles.width20}>No. of Zones</td>
                                    <td>{survey.fireAlarm.Repeater_Panel?.No_Of_Zones ?? 'NA'}</td>
                                </tr>
                        </table>
                    </div>
                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width40} rowspan="2">Make</td>
                                    <td>{survey.fireAlarm.Repeater_Panel?.Make ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>
                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width40} rowspan="2">Model No.</td>
                                    <td>{survey.fireAlarm.Repeater_Panel?.Model_No ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>

                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width60} rowspan="2">Smoke Detectors</td>
                                    <td>{survey.fireAlarm?.Smoke_Detector ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>
                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width60} rowspan="2">Heat Detectors</td>
                                    <td>{survey.fireAlarm?.Heat_Detector ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>
                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width60} rowspan="2">Beam Detectors</td>
                                    <td>{survey.fireAlarm?.Beam_Detector ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>
                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width60} rowspan="2">Multi - Detectors</td>
                                    <td>{survey.fireAlarm?.Multi_Detector ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>

                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width60} rowspan="2">Manual Call Points</td>
                                    <td>{survey.fireAlarm?.Manual_Call_Points ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>
                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width60} rowspan="2">Hooters</td>
                                    <td>{survey.fireAlarm?.Hooters ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>
                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width60} rowspan="2">Response Indicators</td>
                                    <td>{survey.fireAlarm?.Response_Indicators ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>
                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width60} rowspan="2">Hooter Cum Flasher</td>
                                    <td>{survey.fireAlarm?.Hooter_Cum_Flasher ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>

                    <div>
                        <table>
                                <tr>
                                    <td className={styles.width60} rowspan="2">Control Module</td>
                                    <td>{survey.fireAlarm?.Control_Module ?? 'NA'}</td>
                                </tr>
                                <tr></tr>
                        </table>
                    </div>
                    <div className={`${styles.span3} ${styles.remarks}`} >
                        REMARKS: {survey.fireAlarm?.Remarks ?? 'NA'}
                    </div>
                </div>
            </>
        ) : null}

        {survey.firePump ? (
            <>
                <div className={styles.typeOfProd}>
                    <h3 className={styles.typeOfProdH3}>FIRE PUMP ROOM SYSTEM</h3>
                </div>

                <div className={styles.pumpRoom}>
                    <table>
                        <tr>
                            <td className={styles.width20} rowspan="2">Main Hydrant Pump</td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Rating</td>
                            <td>Serial No</td>
                            <td>HP</td>
                            <td>Cut In Pressure</td>
                            <td>Cut Off Pressure</td>
                            <td>Current</td>
                            <td>Voltage</td>
                        </tr>
                        <tr>
                            <td>{ survey.firePump?.Main_Hydrant_Pump?.Make ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Hydrant_Pump?.Model ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Hydrant_Pump?.Rating ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Hydrant_Pump?.Serial_No ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Hydrant_Pump?.Hp ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Hydrant_Pump?.Cut_In_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Hydrant_Pump?.Cut_Off_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Hydrant_Pump?.Current ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Hydrant_Pump?.Voltage ?? 'NA' }</td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className={styles.width20} rowspan="2">Main Sprinkler Pump</td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Rating</td>
                            <td>Serial No</td>
                            <td>HP</td>
                            <td>Cut In Pressure</td>
                            <td>Cut Off Pressure</td>
                            <td>Current</td>
                            <td>Voltage</td>
                        </tr>
                        <tr>
                            <td>{ survey.firePump?.Main_Sprinkler_Pump?.Make ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Sprinkler_Pump?.Model ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Sprinkler_Pump?.Rating ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Sprinkler_Pump?.Serial_No ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Sprinkler_Pump?.Hp ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Sprinkler_Pump?.Cut_In_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Sprinkler_Pump?.Cut_Off_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Sprinkler_Pump?.Current ?? 'NA' }</td>
                            <td>{ survey.firePump?.Main_Sprinkler_Pump?.Voltage ?? 'NA' }</td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className={styles.width20} rowspan="2">Hydrant Jockey Pump</td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Rating</td>
                            <td>Serial No</td>
                            <td>HP</td>
                            <td>Cut In Pressure</td>
                            <td>Cut Off Pressure</td>
                            <td>Current</td>
                            <td>Voltage</td>
                        </tr>
                        <tr>
                            <td>{ survey.firePump?.Hydrant_Jockey_Pump?.Make ?? 'NA' }</td>
                            <td>{ survey.firePump?.Hydrant_Jockey_Pump?.Model ?? 'NA' }</td>
                            <td>{ survey.firePump?.Hydrant_Jockey_Pump?.Rating ?? 'NA' }</td>
                            <td>{ survey.firePump?.Hydrant_Jockey_Pump?.Serial_No ?? 'NA' }</td>
                            <td>{ survey.firePump?.Hydrant_Jockey_Pump?.Hp ?? 'NA' }</td>
                            <td>{ survey.firePump?.Hydrant_Jockey_Pump?.Cut_In_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Hydrant_Jockey_Pump?.Cut_Off_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Hydrant_Jockey_Pump?.Current ?? 'NA' }</td>
                            <td>{ survey.firePump?.Hydrant_Jockey_Pump?.Voltage ?? 'NA' }</td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className={styles.width20} rowspan="2">Sprinkler Jockey Pump</td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Rating</td>
                            <td>Serial No</td>
                            <td>HP</td>
                            <td>Cut In Pressure</td>
                            <td>Cut Off Pressure</td>
                            <td>Current</td>
                            <td>Voltage</td>
                        </tr>
                        <tr>
                            <td>{ survey.firePump?.Sprinkler_Jockey_Pump?.Make ?? 'NA' }</td>
                            <td>{ survey.firePump?.Sprinkler_Jockey_Pump?.Model ?? 'NA' }</td>
                            <td>{ survey.firePump?.Sprinkler_Jockey_Pump?.Rating ?? 'NA' }</td>
                            <td>{ survey.firePump?.Sprinkler_Jockey_Pump?.Serial_No ?? 'NA' }</td>
                            <td>{ survey.firePump?.Sprinkler_Jockey_Pump?.Hp ?? 'NA' }</td>
                            <td>{ survey.firePump?.Sprinkler_Jockey_Pump?.Cut_In_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Sprinkler_Jockey_Pump?.Cut_Off_Pressure ?? 'NA'  }</td>
                            <td>{ survey.firePump?.Sprinkler_Jockey_Pump?.Current ?? 'NA' }</td>
                            <td>{ survey.firePump?.Sprinkler_Jockey_Pump?.Voltage ?? 'NA' }</td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className={styles.width20} rowspan="2">Diesel Engine</td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Rating</td>
                            <td>Serial No</td>
                            <td>HP</td>
                            <td>Cut In Pressure</td>
                            <td>Cut Off Pressure</td>
                            <td>Current</td>
                            <td>Voltage</td>
                        </tr>
                        <tr>
                            <td>{ survey.firePump?.Diesel_Engine?.Make ?? 'NA' }</td>
                            <td>{ survey.firePump?.Diesel_Engine?.Model ?? 'NA' }</td>
                            <td>{ survey.firePump?.Diesel_Engine?.Rating ?? 'NA' }</td>
                            <td>{ survey.firePump?.Diesel_Engine?.Serial_No ?? 'NA' }</td>
                            <td>{ survey.firePump?.Diesel_Engine?.Hp ?? 'NA' }</td>
                            <td>{ survey.firePump?.Diesel_Engine?.Cut_In_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Diesel_Engine?.Cut_Off_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Diesel_Engine?.Current ?? 'NA' }</td>
                            <td>{ survey.firePump?.Diesel_Engine?.Voltage ?? 'NA' }</td>
                        </tr>
                    </table>

                    <table>
                        <tr>
                            <td className={styles.width20} rowspan="2">Booster Pump</td>
                            <td>Make</td>
                            <td>Model</td>
                            <td>Rating</td>
                            <td>Serial No</td>
                            <td>HP</td>
                            <td>Cut In Pressure</td>
                            <td>Cut Off Pressure</td>
                            <td>Current</td>
                            <td>Voltage</td>
                        </tr>
                        <tr>
                            <td>{ survey.firePump?.Booster_Pump?.Make ?? 'NA' }</td>
                            <td>{ survey.firePump?.Booster_Pump?.Model ?? 'NA' }</td>
                            <td>{ survey.firePump?.Booster_Pump?.Rating ?? 'NA' }</td>
                            <td>{ survey.firePump?.Booster_Pump?.Serial_No ?? 'NA' }</td>
                            <td>{ survey.firePump?.Booster_Pump?.Hp ?? 'NA' }</td>
                            <td>{ survey.firePump?.Booster_Pump?.Cut_In_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Booster_Pump?.Cut_Off_Pressure ?? 'NA' }</td>
                            <td>{ survey.firePump?.Booster_Pump?.Current ?? 'NA' }</td>
                            <td>{ survey.firePump?.Booster_Pump?.Voltage ?? 'NA' }</td>
                        </tr>
                    </table>

                    <br />

                    <table>
                        <tr>
                            <td className={styles.width20}>Control Panel</td>
                            <td>Hydrant Pump</td>
                            <td>Jockey - 1</td>
                            <td>Sprinkler Pump</td>
                            <td>Jockey - 2</td>
                            <td>Diesel Engine</td>
                            <td>Booster -1</td>
                        </tr>
                        <tr>
                            <td>Auto / Manual</td>
                            <td>{ survey.firePump?.Control_Panel?.Hydrant_Pump ?? 'NA' }</td>
                            <td>{ survey.firePump?.Control_Panel?.Jockey_1 ?? 'NA' }</td>
                            <td>{ survey.firePump?.Control_Panel?.Sprinkler_Pump ?? 'NA' }</td>
                            <td>{ survey.firePump?.Control_Panel?.Jockey_2 ?? 'NA' }</td>
                            <td>{ survey.firePump?.Control_Panel?.Diesel_Engine ?? 'NA' }</td>
                            <td>{ survey.firePump?.Control_Panel?.Booster_One ?? 'NA' }</td>
                        </tr>
                    </table>
                </div>
                
                <div className={styles.remarks}>Remarks: { survey.firePump?.Remarks ?? 'NA'  }</div>
            </>
        ) : null}

        {/* <div className={styles.buttonDiv}>
            <DownloadButton 
                reportId={survey.report_id} 
                clientName={survey.customerDetails.client_name} 
                type="basic_survey" 
            />
        </div> */}

    </div>
  );
};

export default BasicSurvey;
