import React from "react";
import styles from "./FormB.module.css";
import { useLocation } from 'react-router-dom';
import Letterhead from "../letterHead/letterHead";
// import DownloadButton from "../DownloadButton/downloadButton";

const FormB = () => {
    const location = useLocation();
    const { survey } = location.state || {};

    if (!survey) {
        return null;
    }

    return (
        <div className={styles.container}>
            <Letterhead />

            <div class={styles.formBMain}>
                <h3 class={styles.formBMainH3}>FORM B AUDIT CHECK LIST</h3>
            </div>

            <div className={styles.basicData}>
                <p>Name and Address Of Building: { survey.customerDetails.client_name } { survey.customerDetails.address }</p>
                <p>DATE: {survey.date} <span>Report No: {survey.report_id}</span></p>
            </div>

            <div className={styles.basicInfoTable}>
                <table>
                    <tr className={styles.bgGrey}>
                        <th>Sr. No.</th>
                        <th>Particulars</th>
                        <th>Description</th>
                    </tr>
                    <tr>
                        <td>1</td>
                        <td>Type of Occupancy</td>
                        <td>{ survey.customerDetails?.occupancy ?? 'NA' }</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Contact Details of Building Incharge</td>
                        <td>
                            <p>Name: { survey.customerDetails.Contact_Details_Incharge?.Name ?? 'NA'}</p>
                            <p>Tel. No: { survey.customerDetails.Contact_Details_Incharge?.Tel_No ?? 'NA' }</p>
                            <p>Email ID: { survey.customerDetails.Contact_Details_Incharge?.Email_Id ?? 'NA' }</p>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Details Of Fire NOC / OC</td>
                        <td>
                            <p>Issuing Authority: { survey.customerDetails.Details_Fire_NOC?.Issuing_Authority ?? 'NA' }</p>
                            <p>Letter. No: { survey.customerDetails.Details_Fire_NOC?.Letter_No ?? 'NA' }</p>
                            <p>Date: { survey.customerDetails.Details_Fire_NOC?.Date ?? 'NA' }</p>
                        </td>
                    </tr>
                </table>
            </div>

            <div className={styles.flex}>

                <div className={styles.width50}>

                <div className="ind-table">
                    <div className={styles.typeOfProd}>
                        <h3 className={styles.typeOfProdH3}>A. Fire Extinguishers</h3>
                    </div>

                    <table>
                        <tr>
                            <td>A1</td>
                            <td>ABC</td>
                            <td>{ survey.fireExtinguishers?.ABC ? `${survey.fireExtinguishers.ABC} nos.` : 'N/A' }</td>
                        </tr>
                        <tr>
                            <td>A2</td>
                            <td>CO2</td>
                            <td>{ survey.fireExtinguishers?.CO2 ? `${survey.fireExtinguishers.CO2} nos.` : 'N/A' }</td>
                        </tr>
                        <tr>
                            <td>A3</td>
                            <td>Foam</td>
                            <td>{ survey.fireExtinguishers?.Foam ? `${survey.fireExtinguishers.Foam} nos.` : 'N/A' }</td>
                        </tr>
                        <tr>
                            <td>A4</td>
                            <td>Water</td>
                            <td>{ survey.fireExtinguishers?.Water ? `${survey.fireExtinguishers.Water} nos.` : 'N/A' }</td>
                        </tr>
                        <tr>
                            <td>A5</td>
                            <td>Others</td>
                            <td>{ survey.fireExtinguishers?.Others ? `${survey.fireExtinguishers.Others} nos.` : 'N/A' }</td>
                        </tr>
                        <tr>
                            <td>A6</td>
                            <td>Are they easily accessible?</td>
                            <td>{ survey.fireExtinguishers?.Easily_Accessible ?? 'N/A' }</td>
                        </tr>
                        <tr>
                            <td>A7</td>
                            <td>Are there enough fire extinguishers installed?</td>
                            <td>{ survey.fireExtinguishers?.Enough_Ext_Installed ?? 'N/A' }</td>
                        </tr>
                        <tr>
                            <td>A8</td>
                            <td>Are all extinguishers properly maintained?</td>
                            <td>{ survey.fireExtinguishers?.Ext_Properly_Maintained ?? 'N/A' }</td>
                        </tr>
                    </table>

                    </div>

                    <div class="ind-table">
                        <div className={styles.typeOfProd}>
                            <h3>C. Hydrant and Sprinkler System</h3>
                        </div>

                        <table>
                            <tr>
                                <td>C1</td>
                                <td>Is there a hydrant / riser system installed?</td>
                                <td>{ survey.fireHydrant?.Hydrant_Riser_Installed ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C2</td>
                                <td>No. of courtyard hydrants installed</td>
                                <td>{ survey.fireHydrant?.Num_CourtYard_Hydrant ? `${survey.fireHydrant.Num_CourtYard_Hydrant} Nos.` : 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C3</td>
                                <td>No. of Internal Hydrants Installed.</td>
                                <td>{ survey.fireHydrant?.Num_Internal_Hydrants ? `${survey.fireHydrant.Num_Internal_Hydrants} Nos.` : 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C4</td>
                                <td>No. of Hose Reels Installed</td>
                                <td>{ survey.fireHydrant?.Num_Hose_Reel ? `${survey.fireHydrant.Num_Hose_Reel} Nos.` : 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C5</td>
                                <td>Is Pressure at remotest point more than 3.2 Bar?</td>
                                <td>{ survey.fireHydrant?.Pressure_More_Than_32 ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C6</td>
                                <td>Is the sprinkler system installed?</td>
                                <td>{ survey.fireHydrant?.Sprinkler_Installed ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C7</td>
                                <td>No. of Sprinklers Installed</td>
                                <td>{ survey.fireHydrant?.Num_Sprinkler_Installed ? `${survey.fireHydrant.Num_Sprinkler_Installed} Nos.` : 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C8</td>
                                <td>No. of Alarm Valves Installed</td>
                                <td>{ survey.fireHydrant?.Num_Alarm_Installed ? `${survey.fireHydrant.Num_Alarm_Installed} Nos.` : 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C9</td>
                                <td>Flow Switches Installed?</td>
                                <td>{ survey.fireHydrant?.Flow_Switches_Installed ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C10</td>
                                <td>Are all the above in operating condition?</td>
                                <td>{ survey.fireHydrant?.All_In_Operating_Cond ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C11</td>
                                <td>Are there any leakages in the Piping System?</td>
                                <td>{ survey.fireHydrant?.Any_Leakages ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>C12</td>
                                <td>If yes above, give details:</td>
                                <td>{ survey.fireHydrant?.Details ?? 'N/A' }</td>
                            </tr>
                        </table>

                    </div>

                    <div class="ind-table">
                        <div className={styles.typeOfProd}>
                            <h3>D. Passive Protection System</h3>
                        </div>

                        <table>
                            <tr>
                                <td>D1</td>
                                <td>Is Fire Evacuation Plan Installed?</td>
                                <td>{ survey.passiveProtection?.Fire_Evacuation_Plan ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>D2</td>
                                <td>Is Fire Emergency Instruction Board Installed?</td>
                                <td>{ survey.passiveProtection?.Emergency_Board ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>D3</td>
                                <td>Are adequate Signages provided?</td>
                                <td>
                                    <p>Fire Exit: { survey.passiveProtection?.Adequate_Signages?.Fire_Exit ?? 'N/A' }</p>
                                    <p>Fire Extinguisher: { survey.passiveProtection?.Adequate_Signages?.Fire_Extinguisher ?? 'N/A' }</p>
                                    <p>MCP: { survey.passiveProtection?.Adequate_Signages?.MCP ?? 'N/A' }</p>
                                    <p>Refuge Area: { survey.passiveProtection?.Adequate_Signages?.Refuge_Area ?? 'N/A' }</p>
                                    <p>Hose Reel: { survey.passiveProtection?.Adequate_Signages?.Hose_Reel ?? 'N/A' }</p>
                                </td>
                            </tr>
                            <tr>
                                <td>D4</td>
                                <td>Does Emergency Lighting & Fire Pump System have Emergency Back-up?</td>
                                <td>{ survey.passiveProtection?.Emerygency_Backup ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>D5</td>
                                <td>Location of assembly point identified?</td>
                                <td>{ survey.passiveProtection?.Location_Assembly_Point ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>D6</td>
                                <td>Is assembly Point marked Clearly:</td>
                                <td>{ survey.passiveProtection?.Assembly_Point_Marked ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>D7</td>
                                <td>Is Fire Lift Provided?</td>
                                <td>{ survey.passiveProtection?.Fire_Lift_Provided ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>D8</td>
                                <td>Do all Lifts have a Valid Licence to operate?</td>
                                <td>{ survey.passiveProtection?.Valid_Licence ?? 'N/A' }</td>
                            </tr>
                        </table>

                    </div>  

                </div>

                <div className={styles.width50}>
                    <div class="ind-table">
                        <div className={styles.typeOfProd}>
                            <h3>B. Fire Tanks and Pumps</h3>
                        </div>
            
                        <table>
                            <tr>
                                <td>B1</td>
                                <td>
                                    <h4>Fire Water Tanks: </h4>
                                    <p>Capacity of Underground Tank</p>
                                    <p>Capacity of Overhead Tank</p>
                                </td>
                                <td>
                                    <h4 className={styles.white}>__</h4>
                                    <p>{ survey.firePump?.Fire_Water_Tanks?.Cap_Underground_Tank ? `${survey.firePump.Fire_Water_Tanks.Cap_Underground_Tank} Liters` : 'N/A' }</p>
                                    <p>{ survey.firePump?.Fire_Water_Tanks?.Cap_Overhead_Tank ? `${survey.firePump.Fire_Water_Tanks.Cap_Overhead_Tank} Liters` : 'N/A' }</p>
                                </td>
                            </tr>
                            <tr>
                                <td>B2</td>
                                <td>
                                    <h4>Main Hydrant Pumps</h4>
                                    <p>Mode of Operation: { survey.firePump?.Main_Hydrant_Pump?.Mode_Operation ?? 'N/A' }</p>
                                    <p>Run Test Done? { survey.firePump?.Main_Hydrant_Pump?.Run_Test_Done ?? 'N/A' }</p>
                                </td>
                                <td>
                                    <p>Make: { survey.firePump?.Main_Hydrant_Pump?.Make ?? 'N/A' }</p>
                                    <p>Flow: { survey.firePump?.Main_Hydrant_Pump?.Flow ? `${survey.firePump.Main_Hydrant_Pump.Flow} m³/h` : 'N/A' }</p>
                                    <p>Head: { survey.firePump?.Main_Hydrant_Pump?.Head ? `${survey.firePump.Main_Hydrant_Pump.Head} m` : 'N/A' }</p>
                                    <p>Power: { survey.firePump?.Main_Hydrant_Pump?.Power ? `${survey.firePump.Main_Hydrant_Pump.Power} hp` : 'N/A' }</p>
                                </td>
                            </tr>
                            <tr>
                                <td>B3</td>
                                <td>
                                    <h4>Hydrant Jockey Pump</h4>
                                    <p>Mode of Operation: { survey.firePump?.Hydrant_Jockey_Pump?.Mode_Operation ?? 'N/A' }</p>
                                    <p>Run Test Done? { survey.firePump?.Hydrant_Jockey_Pump?.Run_Test_Done ?? 'N/A' }</p>
                                </td>
                                <td>
                                    <p>Make: { survey.firePump?.Hydrant_Jockey_Pump?.Make ?? 'N/A' }</p>
                                    <p>Flow: { survey.firePump?.Hydrant_Jockey_Pump?.Flow ? `${survey.firePump.Hydrant_Jockey_Pump.Flow} m³/h` : 'N/A' }</p>
                                    <p>Head: { survey.firePump?.Hydrant_Jockey_Pump?.Head ? `${survey.firePump.Hydrant_Jockey_Pump.Head} m` : 'N/A' }</p>
                                    <p>Power: { survey.firePump?.Hydrant_Jockey_Pump?.Power ? `${survey.firePump.Hydrant_Jockey_Pump.Power} hp` : 'N/A' }</p>
                                </td>
                            </tr>
                            <tr>
                                <td>B4</td>
                                <td>
                                    <h4>Main Sprinkler Pump</h4>
                                    <p>Mode of Operation: { survey.firePump?.Main_Sprinkler_Pump?.Mode_Operation ?? 'N/A' }</p>
                                    <p>Run Test Done? { survey.firePump?.Main_Sprinkler_Pump?.Run_Test_Done ?? 'N/A' }</p>
                                </td>
                                <td>
                                    <p>Make: { survey.firePump?.Main_Sprinkler_Pump?.Make ?? 'N/A' }</p>
                                    <p>Flow: { survey.firePump?.Main_Sprinkler_Pump?.Flow ? `${survey.firePump.Main_Sprinkler_Pump.Flow} m³/h` : 'N/A' }</p>
                                    <p>Head: { survey.firePump?.Main_Sprinkler_Pump?.Head ? `${survey.firePump.Main_Sprinkler_Pump.Head} m` : 'N/A' }</p>
                                    <p>Power: { survey.firePump?.Main_Sprinkler_Pump?.Power ? `${survey.firePump.Main_Sprinkler_Pump.Power} hp` : 'N/A' }</p>
                                </td>
                            </tr>
                            <tr>
                                <td>B5</td>
                                <td>
                                    <h4>Sprinkler Jockey Pumps</h4>
                                    <p>Mode of Operation: { survey.firePump?.Sprinkler_Jockey_Pump?.Mode_Operation ?? 'N/A' }</p>
                                    <p>Run Test Done? { survey.firePump?.Sprinkler_Jockey_Pump?.Run_Test_Done ?? 'N/A' }</p>
                                </td>
                                <td>
                                    <p>Make: { survey.firePump?.Sprinkler_Jockey_Pump?.Make ?? 'N/A' }</p>
                                    <p>Flow: { survey.firePump?.Sprinkler_Jockey_Pump?.Flow ? `${survey.firePump.Sprinkler_Jockey_Pump.Flow} m³/h` : 'N/A' }</p>
                                    <p>Head: { survey.firePump?.Sprinkler_Jockey_Pump?.Head ? `${survey.firePump.Sprinkler_Jockey_Pump.Head} m` : 'N/A' }</p>
                                    <p>Power: { survey.firePump?.Sprinkler_Jockey_Pump?.Power ? `${survey.firePump.Sprinkler_Jockey_Pump.Power} hp` : 'N/A' }</p>
                                </td>
                            </tr>
                            <tr>
                                <td>B6</td>
                                <td>
                                    <h4>Diesel Engine Pumps</h4>
                                    <p>Mode of Operation: { survey.firePump?.Diesel_Engine_Pump?.Mode_Operation ?? 'N/A' }</p>
                                    <p>Run Test Done? { survey.firePump?.Diesel_Engine_Pump?.Run_Test_Done ?? 'N/A' }</p>
                                </td>
                                <td>
                                    <p>Make: { survey.firePump?.Diesel_Engine_Pump?.Make ?? 'N/A' }</p>
                                    <p>Flow: { survey.firePump?.Diesel_Engine_Pump?.Flow ? `${survey.firePump.Diesel_Engine_Pump.Flow} m³/h` : 'N/A' }</p>
                                    <p>Head: { survey.firePump?.Diesel_Engine_Pump?.Head ? `${survey.firePump.Diesel_Engine_Pump.Head} m` : 'N/A' }</p>
                                    <p>Power: { survey.firePump?.Diesel_Engine_Pump?.Power ? `${survey.firePump.Diesel_Engine_Pump.Power} hp` : 'N/A' }</p>
                                </td>
                            </tr>
                            <tr>
                                <td>B7</td>
                                <td>
                                    <h4>Booster Pump</h4>
                                    <p>Mode of Operation: { survey.firePump?.Booster_Pump?.Mode_Operation ?? 'N/A' }</p>
                                    <p>Run Test Done? { survey.firePump?.Booster_Pump?.Run_Test_Done ?? 'N/A' }</p>
                                </td>
                                <td>
                                    <p>Make: { survey.firePump?.Booster_Pump?.Make ?? 'N/A' }</p>
                                    <p>Flow: { survey.firePump?.Booster_Pump?.Flow ? `${survey.firePump.Booster_Pump.Flow} m³/h` : 'N/A' }</p>
                                    <p>Head: { survey.firePump?.Booster_Pump?.Head ? `${survey.firePump.Booster_Pump.Head} m` : 'N/A' }</p>
                                    <p>Power: { survey.firePump?.Booster_Pump?.Power ? `${survey.firePump.Booster_Pump.Power} hp` : 'N/A' }</p>
                                </td>
                            </tr>
                        </table>

                    </div>

                    <div class="ind-table">
                        <div className={styles.typeOfProd}>
                            <h3>E. Fire Detection / Alarm System</h3>
                        </div>
        
                        <table>
                            <tr>
                                <td>E1</td>
                                <td>Is Fire Detection included in this Audit?</td>
                                <td>{ survey.fireAlarm?.Fire_Detection_Audit ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>E2</td>
                                <td>Type of fire alarm system:</td>
                                <td>{ survey.fireAlarm?.Fire_Alarm ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>E3</td>
                                <td>Location of fire alarm panel & repeater panel:</td>
                                <td>{ survey.fireAlarm?.Location_Alarm_Repeater ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>E4</td>
                                <td>No. of detectors</td>
                                <td>{ survey.fireAlarm?.Num_Detectors ? `${survey.fireAlarm.Num_Detectors} nos.` : 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>E5</td>
                                <td>No. of Hooters</td>
                                <td>{ survey.fireAlarm?.Num_Hooters ? `${survey.fireAlarm.Num_Hooters} nos.` : 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>E6</td>
                                <td>No. of MCP</td>
                                <td>{ survey.fireAlarm?.Num_MCP ? `${survey.fireAlarm.Num_MCP} nos.` : 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>E7</td>
                                <td>Is PA system installed?</td>
                                <td>{ survey.fireAlarm?.PA_Installed ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>E8</td>
                                <td>Smoke Test Done?</td>
                                <td>{ survey.fireAlarm?.Smoke_Test ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>E9</td>
                                <td>MCP Pull Test Done?</td>
                                <td>{ survey.fireAlarm?.MCP_Pull_Test ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>E11</td>
                                <td>Is the FAS System in working condition?</td>
                                <td>{ survey.fireAlarm?.Fas_System_Working ?? 'N/A' }</td>
                            </tr>
                            <tr>
                                <td>E12</td>
                                <td>If no above, give details:</td>
                                <td>{ survey.fireAlarm?.Details ?? 'N/A' }</td>
                            </tr>
                        </table>

                    </div>
                </div>
            </div>

            <div className={styles.remarksRecommend}>
                <h3>Remarks / Recommendation</h3>
                <p> Here comes the data </p>
            </div>

            <div className={styles.formBLastTable}>
                <table className={styles.noBorderTable}>
                    <thead>
                        <tr>
                            <th>Form B</th>
                            <th>To Be Issued</th>
                            <th>Compliance Needed</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Hydrant and Sprinkler System</td>
                            <td>{ survey.formB?.Hydrant_Sprinkler_System?.To_Be_Issued ?? 'N/A' }</td>
                            <td>{ survey.formB?.Hydrant_Sprinkler_System?.Compliance_Needed ?? 'N/A' }</td>
                        </tr>
                        <tr>
                            <td>Fire Detection System</td>
                            <td>{ survey.formB?.Fire_Detection_System?.To_Be_Issued ?? 'N/A' }</td>
                            <td>{ survey.formB?.Fire_Detection_System?.Compliance_Needed ?? 'N/A' }</td>
                        </tr>
                    </tbody>
                </table>

                <div className={styles.remarksRecommendLast}>
                    <h3>Remarks</h3>
                    <p>{ survey.formB?.Remarks ?? 'N/A' }</p>
                </div>
            </div>


            <div className={styles.flex}>
                <div className={`${styles.declaration} ${styles.width50}`}>
                    <p><strong>Declaration:</strong></p>
                    <p className={styles.justify}>The inspection has been done based on the information provided by the client. Although care has been taken to inspect the premises thoroughly, in case of wrong/misleading/incomplete disclosure by client, we shall not be responsible for the same.</p>
                    <p><strong>Checked and verified:</strong></p>
                    <div className={styles.border}>
                        <p>Signature of Inspecting Officer</p>
                        <br/>
                        <p>Name: </p>
                    </div>
                </div>

                <div className={`${styles.declaration} ${styles.width50}`}>
                    <p><strong>Declaration:</strong></p>
                    <p className={styles.justify}>The inspection has been done in my presence and I agree with the documented observations thereof. I also undertake to expeditiously rectify / repair / renovate the fire systems, wherever necessary.</p>
                    <br/>
                    <p><strong>Checked and verified:</strong></p>
                    <div className={styles.border}>
                        <p>Signature & Seal of Client</p>
                        <br/>
                        <p>Name: </p>
                    </div>
                </div>
            </div>
            
            {/* <div className={styles.buttonDiv}>
                <DownloadButton 
                    reportId={survey.report_id} 
                    clientName={survey.customerDetails.client_name} 
                    type="form_b" 
                />
            </div> */}
            
        </div>
    );
}

export default FormB;