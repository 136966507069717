import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ChevronLeft, ChevronRight, Search } from 'lucide-react';

function SurveyReports() {
  const [API_URL] = useState(process.env.REACT_APP_API_URL); // Access the environment variable

  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState('all');
  const [startFilterDate, setStartFilterDate] = useState('');
  const [endFilterDate, setEndFilterDate] = useState('');
  const [filteredSurveys, setFilteredSurveys] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalReports, setTotalReports] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchSurveys(selectedSurvey);
  }, [selectedSurvey, currentPage, startFilterDate, endFilterDate, searchQuery]);

  const formatDateForServer = (inputDate) => {
    const [year, month, day] = inputDate.split('-');
    return `${day}-${month}-${year}`;
  }

  const fetchSurveys = async (surveyType) => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
  
    // Create a params object to conditionally include only non-empty parameters
    const params = {
      surveysPerPage: 10,
      pageNumber: currentPage,
      type: surveyType !== 'all' ? surveyType : undefined,
      startDate: startFilterDate ? formatDateForServer(startFilterDate) : undefined,
      endDate: endFilterDate ? formatDateForServer(endFilterDate) : undefined,
      searchQuery: searchQuery ? searchQuery : undefined,
    };
  
    try {
      // Use axios with params to dynamically construct the query string
      const response = await axios.get(`${API_URL}/api/reports`, {
        headers: {
          Authorization: `${token}`,
        },
        params, // axios automatically converts this to a query string
      });

      setSurveys(response.data.reports);
      setTotalPages(response.data.totalPages);
      setTotalReports(response.data.totalReports);
      setFilteredSurveys(response.data.reports);

    } catch (error) {
      if (error.response && error.response.status === 404) {
        setSurveys([]);
        setFilteredSurveys([]);
        setTotalPages(0);
        setTotalReports(0);
      } else if ((error.response && error.response.status === 403) || (error.response && error.response.status === 400)) {
        alert('Session expired. You need to log in again.');
        setTimeout(() => {
          localStorage.removeItem('token');
          navigate('/');
        }, 3000);
      } else {
        console.error('Error fetching surveys:', error);
      }
    }finally {
      setIsLoading(false);
    }
  };

  const handleSurveyTypeClick = (surveyType) => {
    setSelectedSurvey(surveyType);
    setCurrentPage(1);
  };

  const handleStartDateChange = (e) => {
    const inputDate = e.target.value;
    const today = new Date().toISOString().split('T')[0];
    
    if (inputDate <= today) {
      setStartFilterDate(inputDate);

      if (inputDate && endFilterDate && new Date(inputDate) > new Date(endFilterDate)) {
        setEndFilterDate(inputDate);
      }

      setCurrentPage(1);
    }
  };

  const handleEndDateChange = (e) => {
    const inputDate = e.target.value;

    setEndFilterDate(inputDate);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    // setSearchQuery(value);

    // Ensure only text input is allowed
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setSearchQuery(value);
    }
  };

  const clearFilter = () => {
    setStartFilterDate('');
    setEndFilterDate('');
    setSearchQuery('');
    setCurrentPage(1);
  };

  // Pagination
  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + 4, totalPages);

    if (endPage - startPage < 4) {
      startPage = Math.max(endPage - 4, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };

  const handleSurveySelect = (reportId) => {
    const selectedSurveyData = surveys.find(survey => survey.report_id === reportId);

    if (selectedSurveyData && selectedSurveyData.type) {
      const surveyType = selectedSurveyData.type;

      if (surveyType === 'Basic_Survey') {
        navigate(`/basicSurvey`, { state: { survey: selectedSurveyData } });
      } else if (surveyType === 'Detailed_Survey') {
        navigate(`/detailedSurvey`, { state: { survey: selectedSurveyData } });
      } else if (surveyType === 'Form_B') {
        navigate(`/formB`, { state: { survey: selectedSurveyData } });
      } else {
        console.warn('Unknown survey type:', surveyType);
      }
    } else {
      console.error('Survey type is missing or invalid for the selected report.');
    }
  };

  return (
    <>
      <div className="flex justify-between items-center mb-8">
        <div className="space-x-4">
          {['all', 'Basic_Survey', 'Detailed_Survey', 'Form_B'].map((type) => (
            <button
              key={type}
              onClick={() => handleSurveyTypeClick(type)}
              className={`px-4 py-2 rounded-md transition-colors duration-300 ${selectedSurvey === type
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                }`}
            >
              {type.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase())}
            </button>
          ))}
        </div>
      </div>

      <h2 className="text-2xl font-bold mb-8 text-center">{selectedSurvey.replace('_', ' ').toUpperCase()} REPORTS</h2>

      <div className="mb-8 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <input
            type="date"
            value={startFilterDate}
            onChange={handleStartDateChange}
            className="border rounded-md px-3 py-2"
            max={new Date().toISOString().split('T')[0]}
          />
          <input
            type="date"
            value={endFilterDate}
            onChange={handleEndDateChange}
            className="border rounded-md px-3 py-2"
            max={new Date().toISOString().split('T')[0]}
            min={startFilterDate}
          />
          <div className="relative">
            <input
              type="text"
              placeholder="Search by client name or engineer name"
              value={searchQuery}
              onChange={handleSearchChange}
              className="border rounded-md px-3 py-2 pl-10 w-64"
            />
            <span className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <Search size={18} className="text-gray-400" />
            </span>
          </div>
          <button onClick={clearFilter} className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors duration-300">Clear Filter</button>
        </div>
        <p className="text-gray-600">Total Reports: {totalReports}</p>
      </div>

      {isLoading ? (
        <div className="animate-pulse">
          {[...Array(5)].map((_, index) => (
            <div key={index} className="h-8 bg-gray-200 mb-4 rounded"></div>
          ))}
        </div>
      ) : (
        <table className="w-full border-collapse border border-gray-300 mb-8">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 px-4 py-2">Report ID</th>
              <th className="border border-gray-300 px-4 py-2">Client</th>
              <th className="border border-gray-300 px-4 py-2">Date</th>
              <th className="border border-gray-300 px-4 py-2">Type</th>
              <th className="border border-gray-300 px-4 py-2">Engineer</th>
              <th className="border border-gray-300 px-4 py-2">Products</th>
            </tr>
          </thead>
          <tbody>
            {filteredSurveys.map((survey) => (
              <tr key={survey.report_id} onClick={() => handleSurveySelect(survey.report_id)} className="hover:bg-gray-50 cursor-pointer">
                <td className="border border-gray-300 px-4 py-2">{survey.report_id}</td>
                <td className="border border-gray-300 px-4 py-2">{survey.customerDetails?.client_name ?? 'Unknown Client'}</td>
                <td className="border border-gray-300 px-4 py-2">{survey.date}</td>
                <td className="border border-gray-300 px-4 py-2">{survey.type?.replace('_', ' ') ?? 'Unknown'}</td>
                <td className="border border-gray-300 px-4 py-2">{survey.engineerName ?? 'No Engineer'}</td>
                <td className="border border-gray-300 px-4 py-2">
                  {survey.type === 'Basic_Survey' && (
                    <>
                      {(survey.fireExtinguishers || survey.fireHydrant || survey.fireAlarm || survey.firePump) ? (
                        <>
                          {survey.fireExtinguishers && <p>Fire Extinguishers</p>}
                          {survey.fireHydrant && <p>Fire Hydrant</p>}
                          {survey.fireAlarm && <p>Fire Alarm</p>}
                          {survey.firePump && <p>Fire Pump</p>}
                        </>
                      ) : (
                        <p>N/A</p>
                      )}
                    </>
                  )}
                  {survey.type === 'Detailed_Survey' && (
                    <>
                      {(survey.fireExtinguishers || survey.fireHydrant || survey.fireAlarm || survey.boosterPump || survey.firePump) ? (
                        <>
                          {survey.fireExtinguishers && <p>Fire Extinguishers</p>}
                          {survey.fireHydrant && <p>Fire Hydrant</p>}
                          {survey.fireAlarm && <p>Fire Alarm</p>}
                          {survey.boosterPump && <p>Booster Pump</p>}
                          {survey.firePump && <p>Fire Pump</p>}
                        </>
                      ) : (
                        <p>N/A</p>
                      )}
                    </>
                  )}
                  {survey.type === 'Form_B' && (
                    <>
                      {(survey.fireExtinguishers || survey.firePump || survey.fireHydrant || survey.passiveProtection || survey.fireAlarm) ? (
                        <>
                          {survey.fireExtinguishers && <p>Fire Extinguishers</p>}
                          {survey.firePump && <p>Fire Pump</p>}
                          {survey.fireHydrant && <p>Fire Hydrant</p>}
                          {survey.passiveProtection && <p>Passive Protection</p>}
                          {survey.fireAlarm && <p>Fire Alarm</p>}
                        </>
                      ) : (
                        <p>N/A</p>
                      )}
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="flex justify-center items-center space-x-2">
        <button
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
          className={`px-3 py-1 border rounded-md flex items-center ${currentPage === 1 ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}
        >
          <ChevronLeft size={18} />
          Previous
        </button>
        {getPageNumbers().map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => setCurrentPage(pageNumber)}
            className={`px-3 py-1 border rounded-md transition-colors duration-300 ${currentPage === pageNumber
              ? 'bg-blue-500 text-white'
              : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
              }`}
            aria-label={`Page ${pageNumber}`}
            aria-current={currentPage === pageNumber ? 'page' : undefined}
          >
            {pageNumber}
          </button>
        ))}
        <button
          onClick={goToNextPage}
          disabled={currentPage === totalPages}
          className={`px-3 py-1 border rounded-md flex items-center ${currentPage === totalPages ? 'bg-gray-100 text-gray-400 cursor-not-allowed' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}
        >
          Next
          <ChevronRight size={18} />
        </button>
      </div>
    </>
  );
}

export default SurveyReports;