import React from 'react';
import { NavLink } from 'react-router-dom';
import { FileText, Settings } from 'lucide-react';
import Logout from './Logout';

export default function Sidebar({setIsLoggedIn}) {
  return (
    <aside className="w-64 bg-white text-gray-600 fixed top-16 left-0 h-[calc(100vh-4rem)] flex flex-col shadow-md">
      {/* The w-64 shows the wodth of the aside.. If in sidebar we change the w-64 then we need to change the same in layout ml-64 */}
      <div className="flex-grow p-4 space-y-2">
        <NavLink
          to="/surveyOptions"
          className={({ isActive }) =>
            `flex items-center space-x-2 p-2 rounded-lg ${
              isActive
                ? 'bg-purple-100 text-purple-600'
                : 'hover:bg-purple-100 hover:text-purple-600'
            }`
          }
        >
          <FileText size={20} />
          <span>Survey Options</span>
        </NavLink>
        <NavLink
          to="/amc"
          className={({ isActive }) =>
            `flex items-center space-x-2 p-2 rounded-lg ${
              isActive
                ? 'bg-purple-100 text-purple-600'
                : 'hover:bg-purple-100 hover:text-purple-600'
            }`
          }
        >
          <Settings size={20} />
          <span>AMC</span>
        </NavLink>
      </div>
      <div className="p-4 border-t border-gray-200">
        <Logout setIsLoggedIn={setIsLoggedIn}/>
      </div>
    </aside>
  )
}