import React from "react";
import styles from "./DetailedSurvey.module.css";
import { useLocation } from 'react-router-dom';
import Letterhead from "../letterHead/letterHead";
// import DownloadButton from "../DownloadButton/downloadButton";

const DetailedSurvey = () => {
    const location = useLocation();
    const { survey } = location.state || {};

    if (!survey) {
        return null;
    }

    return (
        <div className={styles.container}>
            <Letterhead />

            <div className={styles.typeOfProd}>
                <h3>Fire Audit Report</h3>
            </div>

            <div className={styles.basicInfoTable}>
                <table>
                        <tr>
                            <td className= {`${styles.small} ${styles.width50} ${styles.basicInfoTd}`}>Name of Client: {survey.customerDetails?.client_name ?? 'NA'}</td>
                            <td>Contact Person 1: {survey.customerDetails?.contact_person_1 ?? 'NA'}</td>
                            <td>Date: {survey.date}</td>
                        </tr>
                        <tr>
                            <td className= {`${styles.small} ${styles.width50}`}>Address: {survey.customerDetails?.address ?? 'NA'}</td>
                            <td>Contact Person 2: {survey.customerDetails?.contact_person_2 ?? 'NA'}</td>
                            <td>Report No: {survey.report_id}</td>
                        </tr>
                        <tr>
                            <td colspan="3">Building: {survey.customerDetails?.building ?? 'NA'}</td>
                        </tr>
                </table>
            </div>

            {survey.fireHydrant && survey.fireHydrant.length > 0 ? (
                <>
                    <div className={styles.typeOfProd}>
                        <h3 className={styles.typeOfProdH3}>Fire Hydrant System</h3>
                    </div>

                    <div className={styles.basicInfoTable}>
                        <table>
                                <tr>
                                    <td className={styles.width10} rowspan="2">Location</td>
                                    <td className={styles.center} colspan="6">HYDRANT</td>
                                    <td className={styles.center} colspan="7"> HOSE BOX</td>
                                </tr>
                                <tr>
                                    <td>Hydrant Coupling</td>
                                    <td>Latch</td>
                                    <td>Gland Nut</td>
                                    <td>Hydrant Cap</td>
                                    <td>Coupling Washer</td>
                                    <td>Wheel / Wheel Nut</td>
                                    <td>Short branch pipe</td>
                                    <td>rubber bidding</td>
                                    <td>key</td>
                                    <td>key glass</td>
                                    <td>hose box blass</td>
                                    <td>hose condition</td>
                                    <td>hammer</td>
                                </tr>
                            { survey.fireHydrant.map((hydrant) => (
                                <tr>
                                    <td>{ hydrant.Location ?? 'NA' }</td>
                                    <td>{ hydrant.Hydrant_Coupling ?? 'NA' }</td>
                                    <td>{ hydrant.Latch ?? 'NA' }</td>
                                    <td>{ hydrant.Gland_Nut ?? 'NA' }</td>
                                    <td>{ hydrant.Hydrant_Cap ?? 'NA' }</td>
                                    <td>{ hydrant.Coupling_Washer ?? 'NA' }</td>
                                    <td>{ hydrant.Wheel_Wheel_Nut ?? 'NA' }</td>
                                    <td>{ hydrant.Short_Branch_Pipe ?? 'NA' }</td>
                                    <td>{ hydrant.Rubber_Bidding ?? 'NA' }</td>
                                    <td>{ hydrant.Key ?? 'NA' }</td>
                                    <td>{ hydrant.Key_Glass ?? 'NA' }</td>
                                    <td>{ hydrant.Hose_Box_Glass ?? 'NA' }</td>
                                    <td>{ hydrant.Hose_Condition ?? 'NA' }</td>
                                    <td>{ hydrant.Hammer ?? 'NA' }</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                    {/* <div class = {styles.remarks}>Remarks: Keep tdis remarks</div> */}
                </>
            ) : null}

            {survey.fireAlarm && survey.fireAlarm.length > 0 ? (
                <>
                    <div class = {styles.typeOfProd}>
                        <h3>Fire HOSE REEL DRUM & FIRE ALARM System </h3>
                    </div>

                    <div className={styles.basicInfoTable}>
                        <table>
                                <tr>
                                    <td class = {styles.width10} rowspan="2">Location</td>
                                    <td class = {styles.center} colspan="4">HOSE REEL DRUM</td>
                                    <td class = {styles.center} colspan="5">FIRE ALARM SYSTEM</td>
                                </tr>
                                <tr>
                                    <td>RUBBER HOSE CONDITION</td>
                                    <td>OPERATING VALVE CONDITION</td>
                                    <td>Shut Off NOZZLE</td>
                                    <td>Drum Movement</td>
                                    <td>Panel condition</td>
                                    <td>smoke / heat detector</td>
                                    <td>beam detector</td>
                                    <td>MCP</td>
                                    <td>Hooter</td>
                                </tr>
                            { survey.fireAlarm.map((hose_alarm) => (
                                <tr>
                                    <td>{ hose_alarm.Location ?? 'NA' }</td>
                                    <td>{ hose_alarm.Rubber_Hose_Condition ?? 'NA' }</td>
                                    <td>{ hose_alarm.Operating_Valve_Condition ?? 'NA' }</td>
                                    <td>{ hose_alarm.Shut_Off_Nozzle ?? 'NA' }</td>
                                    <td>{ hose_alarm.Drum_Movement ?? 'NA' }</td>
                                    <td>{ hose_alarm.Panel_Condition ?? 'NA' }</td>
                                    <td>{ hose_alarm.Smoke_Heat_Detector ?? 'NA' }</td>
                                    <td>{ hose_alarm.Beam_Detector ?? 'NA' }</td>
                                    <td>{ hose_alarm.MCP ?? 'NA' }</td>
                                    <td>{ hose_alarm.Hooter ?? 'NA' }</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                </>
            ) : null}

                <br/>
                {survey.boosterPump && survey.boosterPump.length > 0 ? (
                    <>
                        <table>
                                <tr>
                                    <td class = {styles.width10} rowspan="2">Booster Pump</td>
                                    <td class = {styles.center}>Gland</td>
                                    <td class = {styles.center}>Motor Fan</td>
                                    <td class = {styles.center}>Motor connection</td>
                                    <td class = {styles.center}>Foundation</td>
                                    <td class = {styles.center}>Valve</td>
                                    <td class = {styles.center}>Remarks</td>
                                </tr>
                                <tr>
                                    <td>{ survey.boosterPump.Gland ?? 'NA' }</td>
                                    <td>{ survey.boosterPump.Motor_Fan ?? 'NA' }</td>
                                    <td>{ survey.boosterPump.Motor_Connection ?? 'NA' }</td>
                                    <td>{ survey.boosterPump.Foundation ?? 'NA' }</td>
                                    <td>{ survey.boosterPump.Valve ?? 'NA' }</td>
                                    <td>{ survey.boosterPump.Remarks ?? 'NA' }</td>
                                </tr>
                        </table>
                    </>
                ) : null}

            {survey.firePump && survey.firePump.length > 0 ? (
                <>
                    <div class = {styles.typeOfProd}>
                        <h3>Fire Pump</h3>
                    </div>

                    <div className={styles.basicInfoTable}>
                        <table>
                                <tr>
                                    <td class = {styles.width10} rowspan="2">Sr. No.</td>
                                    <td class = {styles.center} rowspan="2">Pump</td>
                                    <td class = {styles.center} colspan="11">FIRE Fighting Pump</td>
                                </tr>
                                <tr>
                                    <td>Capacity</td>
                                    <td>Gland</td>
                                    <td class = {styles.center}>Motor Fan</td>
                                    <td class = {styles.center}>Cable connection</td>
                                    <td class = {styles.center}>Foundation</td>
                                    <td class = {styles.center}>Movement</td>
                                    <td class = {styles.center}>Winding</td>
                                    <td class = {styles.center}>Valve</td>
                                    <td class = {styles.center}>Pressure Switch</td>
                                    <td class = {styles.center}>Pressure Gauge</td>
                                    <td class = {styles.center}>Panel Condition</td>
                                </tr>
                            { survey.firePump.map((pump, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{ pump.Pump ?? 'NA' }</td>
                                    <td>{ pump.Capacity ?? 'NA' }</td>
                                    <td>{ pump.Gland ?? 'NA' }</td>
                                    <td>{ pump.Motor_Fan ?? 'NA' }</td>
                                    <td>{ pump.Cable_Connection ?? 'NA' }</td>
                                    <td>{ pump.Foundation ?? 'NA' }</td>
                                    <td>{ pump.Movement ?? 'NA' }</td>
                                    <td>{ pump.Winding ?? 'NA' }</td>
                                    <td>{ pump.Valve ?? 'NA' }</td>
                                    <td>{ pump.Pressure_Switch ?? 'NA' }</td>
                                    <td>{ pump.Pressure_Gauge ?? 'NA' }</td>
                                    <td>{ pump.Panel_Condition ?? 'NA' }</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                    <div class = {styles.remarks}>Remarks: Keep tdis remarks</div>
                </>
            ) : null}

            {survey.fireExtinguishers && survey.fireExtinguishers.length > 0 ? (
                <>
                    <div class = {styles.typeOfProd}>
                        <h3>Fire Extinguishers</h3>
                    </div>

                    <div className={styles.basicInfoTable}>
                        <table>
                            <tr>
                                <td>Sr. No</td>
                                <td>Location</td>  
                                <td>Type</td>  
                                <td>Capacity</td>  
                                <td>HPT Due</td>  
                                <td>HPT Done</td>  
                                <td>Refilling Due</td>  
                                <td>Refilling Done</td>  
                                <td>Pressure Gauge</td>  
                                <td>Hose Pipe</td>  
                                <td>Nozzle Horn</td> 
                                <td>Safety Pin</td>  
                                <td>Body sticker</td>
                                <td>Body belt</td>  
                                <td>Valve</td>  
                                <td>Body condition</td>  
                            </tr>
                            { survey.fireExtinguishers.map((extinguisher, index) => (
                                <tr key={index}>
                                    <td>{ index + 1 }</td>
                                    <td>{ extinguisher.Location ?? 'NA' }</td>
                                    <td>{ extinguisher.Type ?? 'NA' }</td>
                                    <td>{ extinguisher.Capacity ?? 'NA' }</td>
                                    <td>{ extinguisher.HPT_Due ?? 'NA' }</td>
                                    <td>{ extinguisher.HPT_Done ?? 'NA' }</td>
                                    <td>{ extinguisher.Refilling_Due ?? 'NA' }</td>
                                    <td>{ extinguisher.Refilling_Done ?? 'NA' }</td>
                                    <td>{ extinguisher.Pressure_Gauge ?? 'NA' }</td>
                                    <td>{ extinguisher.Hose_Pipe ?? 'NA' }</td>
                                    <td>{ extinguisher.Nozzle_Horn ?? 'NA' }</td>
                                    <td>{ extinguisher.Safety_Pin ?? 'NA' }</td>
                                    <td>{ extinguisher.Body_Sticker ?? 'NA' }</td>
                                    <td>{ extinguisher.Body_Belt ?? 'NA' }</td>
                                    <td>{ extinguisher.Valve ?? 'NA' }</td>
                                    <td>{ extinguisher.Body_Condition ?? 'NA' }</td>
                                </tr>
                            ))}
                        </table>
                    </div>
                    <div class = {styles.remarks}>Remarks: {survey.remarks || "NA"}</div>
                </>
            ) : null}

            {/* <div className={styles.buttonDiv}>
                <DownloadButton 
                    reportId={survey.report_id} 
                    clientName={survey.customerDetails.client_name} 
                    type="detailed_survey" 
                />
            </div> */}
            
        </div>
    );
}

export default DetailedSurvey;